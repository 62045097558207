<script>
import simplebar from "simplebar-vue";

import SideNavAdmin from "./side-nav-admin";
import SideNavStore from "./side-nav-store";

export default {
  components: { simplebar, SideNavAdmin, SideNavStore },
  props: {
    isCondensed: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60
      },
      userRole: 'store',
    };
  },

  methods: {
    isAdmin() {
      return this.userRole === 'admin'
    },
    isStore() {
      return this.userRole === 'store'
    }
  },
  mounted() {
    this.$store.dispatch('USER_DATA').then((res) => {
      this.userRole = res.role;
    })
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <simplebar v-if="!isCondensed" :settings="settings" class="h-100">
      <!-- <SideNav /> -->
      <SideNavAdmin v-if="isAdmin()"/>
      <SideNavStore v-if="isStore()"/>
    </simplebar>

    <simplebar v-else class="h-100">
      <!-- <SideNav /> -->
      <SideNavAdmin v-if="isAdmin()"/>
      <SideNavStore v-if="isStore()"/>
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>

