<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      userRole: '',
    }
  },
  computed: mapState(['notifications']),
  mounted: function() {
    document.body.setAttribute("data-sidebar", "light");
    document.body.setAttribute("data-topbar", "dark");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }

    this.$store.dispatch('USER_DATA').then((res) => {
      this.userRole = res.role;
    })
  },
  methods: {
    isAdmin() {
      return this.userRole === 'admin';
    },
    isStore() {
      return this.userRole === 'store';
    },
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!-- Sidebar -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="menu-title">Menu</li>

      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <i class="bx bx-home-circle"></i>
          <span>Dashboard</span>
        </router-link>
      </li>

      <li>
        <router-link tag="a" to="/store/sales" class="side-nav-link-ref">
          <i class="bx bx-cart"></i>
          <span>Pedidos</span>
        </router-link>
      </li>

      <li>
        <router-link tag="a" to="/store/products" class="side-nav-link-ref">
          <i class="bx bx-package"></i>
          <span>Produtos</span>
        </router-link>
      </li>

      <li>
        <router-link tag="a" to="/chat" class="side-nav-link-ref">
          <i class="bx bx-support"></i>
          <span class="badge badge-pill badge-primary float-right" v-if="notifications.count > 0">{{notifications.count}}</span>
          <span>Atendimento</span>
        </router-link>
      </li>

      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bx-cog"></i>
          <span>Configurações</span>
        </a>
        <ul>
          <li>
            <router-link tag="a" to="/store/settings" class="side-nav-link-ref">Dados da Loja</router-link>
          </li>
          <li>
            <router-link tag="a" to="/store/shipping" class="side-nav-link-ref">Entrega</router-link>
          </li>
          <li>
            <router-link tag="a" to="/store/working-hours" class="side-nav-link-ref">Horário</router-link>
          </li>
          <li>
            <router-link tag="a" to="/store/bank" class="side-nav-link-ref">Dados Bancários</router-link>
          </li>
          <li>
            <router-link tag="a" to="/store/import-products" class="side-nav-link-ref">Atualizar Produtos</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
