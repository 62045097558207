<script>
import defaultUserImage from '@/assets/images/avatar.png'
import simplebar from "simplebar-vue";
import socket from '@/sockets/client'
import { mapState } from 'vuex';
import UserService from '@/services/user';

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      user: {},
      defaultUserImage
    }
  },
  components: { simplebar },
  computed: mapState(['notifications']),
  methods: {
    getUserData() {
      this.$store.dispatch('USER_DATA').then((res) => {
        this.user = res;

        socket.emit('subscribe', this.user._id)
      }).catch(() => {
        // console.log(err)
      })
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    logout() {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        socket.emit('unsubscribe', this.user._id)
        this.$router.push('/login')
      })
    }
  },
  mounted() {
    this.getUserData();

    const pushId = this.$store.getters.pushId;

    if (pushId) {
      UserService.updatePushId(pushId).then(() => {

      })
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-whey-white.png" alt height="14" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-whey-white.png" alt height="34" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-whey-white.png" alt height="14" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-whey-white.png" alt height="34" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <!-- <b-dropdown
          class="d-inline-block d-lg-none ml-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown> -->

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell" :class="{'bx-tada': notifications.count > 0}"></i>
            <span class="badge badge-primary badge-pill" v-if="notifications.count">{{notifications.count}}</span>
          </template>

          <div class="p-3" v-if="notifications.count > 0 && notifications.messages.length > 0">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notificações</h6>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px;" v-if="notifications.count > 0 && notifications.messages.length > 0">
            <!-- <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Novo Pedido</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">Você recebeu um novo pedido</p>
                  </div>
                </div>
              </div>
            </a> -->

            <div class="text-reset notification-item" v-for="(message, index) in notifications.messages" :key="index">
              <router-link tag="a" to="/chat">
                <div class="media">
                  <div class="avatar-xs mr-3">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                      <i class="bx bx-support"></i>
                    </span>
                  </div>
                  <div class="media-body">
                    <h6 class="mt-0 mb-1">{{message.title}}</h6>
                    <div class="font-size-12 text-muted">
                      <p class="mb-1">{{message.text}}</p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </simplebar>
        </b-dropdown>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user mr-2"
              :src="`${user.image || defaultUserImage}`"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ml-1">{{user.name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block ml-2"></i>
          </template>
          <!-- item-->
          <!-- <a class="dropdown-item d-block" href="javascript: void(0);">
            <i class="bx bx-user-circle font-size-16 align-middle mr-1"></i> Meu Perfil
          </a>
          <div class="dropdown-divider"></div> -->
          <a class="dropdown-item text-danger" @click="logout">
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Sair
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
